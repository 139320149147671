@import url('https://fonts.googleapis.com/css?family=Barlow:400,600,800|Josefin+Sans:400,700');
@import url('https://file.myfontastic.com/YZMdYDYNsTiPzECzmtJ5kQ/icons.css');
@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
@import "../../bower_components/slick-carousel/slick/slick.scss";
// endbower
@import "../../bower_components/slick-carousel/slick/slick-theme.scss";

@import "common/global";
@import "common/mobile";
@import "components/buttons";
@import "components/checkbox";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
// @import "components/slick-carousel";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
