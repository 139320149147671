html, body {
  height: 100%;

}
body {
  font-family: 'Barlow', sans-serif;
  font-size:1.2rem;
  color: #777777;
  overflow-x: hidden; /* needed because hiding the menu on the right side is not perfect,  */
}

h1{

}
h2{

}

h3{

}
.container-box{
  border-radius: 2px;
  margin-bottom:30px;

}
.inner-box{
  padding:40px;
}
.offset-box{
  height:100px;
 }

.force-left{
  text-align:left !important;
}
.force-right{
  text-align:right !important;
}
.center{
  margin: 0 auto;
  text-align:center;
}
.grey-bg{
  background-color: $bsb-grey;
}
.white-bg{
  background-color:$bsb-white;
}
.bsb-blue{
  background-color: $bsb-blue;
}

nav{
  height: 87px;
}
.show>.nav-pills .nav-link{
  background-color: #ffffff;
}
.no-gutters{
  padding: 0;
    margin: 0;
}


.bsb-btn{
  font-size: 18px;
  color:$bsb-white;
  background-color: $bsb-blue;
  border-color: $bsb-blue;
  border-radius: 3px;
  padding:15px 30px;
  font-weight:600;
  text-align: center;
  &:hover{
    color:$bsb-white;
    background-color:#406175;

  }

}
.btn-margin{
margin:30px 0px;
}
.bsb-product{
  border-radius: 2px;
  margin-bottom: 30px;
}
.bsb-product-txt{
  padding:86px 86px 40px 40px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.56;
  text-align: left;
  background-color: $bsb-grey;

}
.bsb-product-txt h1{
  font-size: 34px;
  font-weight: 800;
  color: $bsb-blue;
  text-transform:uppercase;
  margin-bottom: 30px;
}
.bsb-product-txt ul{
padding-left:20px;
margin:0px;
text-align: left;
}
.bsb-product-img{
  background-color: rgba(0, 0, 0, 0.57);
  background: url("../images/irm.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.bsb-product-full-img{
  height:316px;
  margin-bottom:30px;
}


.bsb-advisors{
  border-radius: 2px;
  margin-bottom: 30px;
}
.bsb-advisors-txt{
  padding:86px 86px 40px 40px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.56;
  text-align: left;
  background-color: $bsb-grey;

}
.bsb-advisors-txt h1{
  font-size: 34px;
  font-weight: 800;
  color: $bsb-blue;
  text-transform:uppercase;
  margin-bottom: 30px;
}
.bsb-advisors-img{
  background-color: rgba(0, 0, 0, 0.57);

}
.bsb-advisor-scroll{
  height:95px;
}
.scroll-btn{

}
.home-product-img{
  background-repeat: no-repeat;
  background-size:  cover;
  background-position: center center;
}
.main-product-img{
  background-repeat: no-repeat;
  background-size:  cover;
  background-position: center center;
}




.solutions-list ul{
  text-align: left;
}


/* ADVISOR CSS */
.advisor{

}
.advisor h1{

}
.advisor h2{

}
.advisor h3{

}



.advisor-profile{
  height:290px;
  margin:15px 0px;
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-size:  cover;
  background-position: center center;

}


.profile-overlay {
  display: none;
   position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: $bsb-blue;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  padding:30px;
  background-color: rgba(255, 255, 255, 0.9);

}
.advisor-profile h1{
  margin-top:140px;
  font-weight: 800;
  font-size: 18px;
  text-transform: uppercase;

}

.advisor-profile:hover .profile-overlay {
  display: block;
}
.contact-box{
  margin-bottom:30px;

}
.staff-box{
  font-size: 18px;

  text-align: left;
  color: #777777;
  text-align: left;

}
.staff-box h1{
  font-size: 34px;
  font-weight: 800;
   color: $bsb-blue;
   text-transform: uppercase;
}
.staff-box h2{
  font-size: 18px;
  font-weight:600;
  text-transform: uppercase;


 }
 .staff-box h3{
  font-size: 14px;
  margin-top:13px;

}
.staff-box p{
  font-size: 18px;
  margin-bottom:0px;
}

.team-select {
  height: 44px !important;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: $bsb-blue;
  text-transform: uppercase;
}

.quote{
  border-radius: 2px;
  background-color: $bsb-grey;
  padding:31px;

}
.quote-text{
  font-size: 18px;
  font-weight: 500;
  line-height: 1.56;
  text-align: center;
  padding:10px 30px;

 }
 .quote-form{

  }

 .quoteMessage-input{
   height:202px;
 }
 .quote-input{
  height:67px;

 }

 .contact-form{
  border-radius: 2px;
  background-color: $bsb-grey;
  padding:31px;

}
.contact-text{
  font-size: 18px;
  font-weight: 500;
  line-height: 1.56;
  text-align: center;
  padding:10px 30px;

 }
 .section-title{
  font-size: 34px;
  font-weight: 800;
  text-align: center;
  color: $bsb-blue;
  text-transform:uppercase;
  padding:30px 30px 0px 30px;

 }
 .contact-form{

  }
  
.form-row{
  margin-bottom:20px;
}
 .contactMessage-input{
   height:202px;
 }
 .contact-input{
  height:67px;
  width: 95%;
 }

 .contact-map{
  font-size: 18px;
  font-weight: 500;
  line-height: 1.56;
  text-align: left;
  color: #777777;
  margin-bottom:40px;
  overflow:hidden;
  .row{
  margin:20px 0px;
  background-color: $bsb-grey;
  }

 }
 .contact-map-title{
  font-size: 34px;
  font-weight: 800;
  text-align: left;
  color: $bsb-blue;
  text-transform: uppercase;
  margin-bottom:30px;
 }
 .map-address{
   padding:50px;

 }


 /* .map-responsive{

  padding-bottom:56.25%;
  position:relative;
}*/

.map-responsive {
 position: relative;
  padding-bottom: 75%; // This is the aspect ratio
  height: 0;
  // overflow: hidden;
}
.map-responsive iframe{
  left:0;
  top:0;
  width: 100% !important;
  height: 100% !important;
 position:absolute;
}


.form-control{
  border-top: 1px solid $bsb-grey;
  border-bottom: 2px solid #cccccc;
  border-right: 1px solid $bsb-grey;
  border-left: 1px solid $bsb-grey;
  border-radius:2px;


}
.send-btn {
  color:$bsb-white;
  background-color: $bsb-blue;
  border-color: $bsb-blue;
  border-radius: 2px;
  padding:15px 30px;
  font-weight:600;
}



.profile{
  font-size: 18px;
  background-color: $bsb-grey;

}
.profile-contact {
padding:00px 20px 20px 20px;
color:$bsb-darkgrey;
  h1{

  }
  h2{
  }
  h3{
    font-size: 18px;
    font-weight: 800;
    text-transform:uppercase;

  }
  p{
    font-size: 18px;
    font-weight: 500;
  }
}
.profile-text{
padding:40px;
font-size: 18px;
font-weight: 500;
line-height: 1.56;
}
.profile-social{
  margin-bottom:20px;
}

.profile-social i{
  margin-right:30px;
  font-size:2.2em;
}
.main-profile-pic{
  margin-bottom:40px;
  height:550px;
  background-repeat: no-repeat;
  background-size:  cover;
  background-position: center center;

}



 .home-advisor-button{
  background-color:#4d738a;
  color:#ffffff;
  border-radius: 2px;
  border:none;
  padding:15px 25px;

}
.home-advisor-text{

}
.home-advisor-text h2{
  font-size: 18px;
  font-weight: 800;
  color: #777777;
  text-transform: uppercase;
  a{
    text-decoration: none;
    color: #777777;
    &:hover{
      color: #4d738a

    }
  }
}
.home-advisor-text p{
  font-size: 18px;
  font-weight: 500;
  color: #777777;
}
.home-advisors-bottom{
   padding:20px;

}


.advisor-mobile-name{
display: none;

  h1{
    font-size: 18px;
    font-weight: 800;
    color: #777777;
    text-transform: uppercase;
    a{
      text-decoration: none;
      color: #777777;
      &:hover{
        color: #4d738a
      }
    }
  p{
   color: #777777;

    }
}

}



//TESTIMONIALS PAGE

.testimonial-profile-img{


}
.main-testimonial{
  p {
  font-family: Barlow;
  font-size: 18px;
  font-weight: 500;
  }

}

.main-testimonial-author{
p {
  font-family: Barlow;
  font-size: 24px;
  font-weight: 600;
  }

}
.testimonial-profile-box{
  padding:20px;
}

.testimonial-social {

  i {
    margin: 10px;
    font-size: 1.8em;
    color:#404040;
}
}

.sta--404-header {
  font-size:220px !important;
  font-weight:bold;
  font-family:inherit;
  margin-bottom:-30px;
}
.sta--404-subhead {
  font-size:40px;
  font-weight:normal;
  font-family: inherit;
}
.sta--404-text {
  font-size: 16px;
  font-weight: normal;
  font-family: inherit;
}
