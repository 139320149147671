
.footer{
  background-color: $bsb-grey;
  margin-top:42px;

}
.footer-top{
  border-bottom: solid 1px #e5e5e5;
  padding:28px 0px;

}
.footer-mid{
  font-size: 14px;
  font-weight: 600;
  line-height: 2.0;
  color: $bsb-darkgrey;
  text-align: left;
  border-bottom: solid 1px #e5e5e5;
  padding:28px 0px;
    ul{
      list-style: none;
      padding:0;
      column-count: 2;

    }
    a{
      text-decoration: none;
      color: $bsb-darkgrey;
     &:hover{
        color:$bsb-blue;
        text-decoration: underline;
      }

    }

}

.footer-mid-title{
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 800;
}

.social-icons{
  margin-bottom:20px;
 i{
  margin-right:20px;
  font-size:16px;
  }
}
.fa-envelope{color: #0077b5; }
.fa-linkedin-in{color: #0077b5; }
.fa-twitter{color: #1da1f2; }
.fa-facebook-f{color: #3b5998; }
.fa-youtube{color: #cd201f; }

.footer-btm{
  height:55px;
  padding:28px 0px;
}

.copyright{
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 1.8px;
}

.footer-nav{
text-align:center;
font-size: 13px;
font-weight: 500;
  a{
    text-decoration: none;
    color: $bsb-darkgrey;
    &:hover{
        color:$bsb-blue;
        text-decoration: underline;
      }
    }
}

.footer-nav ul{
    margin: 0;
    padding: 0;
}

.footer-nav ul li{
    list-style-type: none;
    display: inline;
}

.footer-nav li:not(:first-child):before {
    content: " | ";
    height:50px;
    margin:0px 15px;
}
