// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
  width:250px !important;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.wpcf7-form .wpcf7-response-output.wpcf7-validation-errors {
  color: #D8000C;
  background-color: #FFBABA;
  border: 0;
  padding: 10px;
}
.wpcf7-form .wpcf7-response-output.wpcf7-mail-sent-ok {
  color: #4F8A10;
  background-color: #DFF2BF;
  border: 0;
  padding: 10px;
}
div.wpcf7-mail-sent-ok:before,div.wpcf7-validation-errors:before {
  font: 26px/30px FontAwesome;
  margin-right: 16px;
  vertical-align: middle;
}
div.wpcf7-mail-sent-ok:before {
  content:'\f00c';
}
div.wpcf7-validation-errors:before {
  content:'\f057';
}

span.wpcf7-list-item {
  margin: 0 1.5em 0 1.5em;
}


.search-form .search-submit {
  color: #fff;
  background-color:  $bsb-blue;
  border-color:  $bsb-blue;
  }
