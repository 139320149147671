/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  /* Styles */

  .page-header.mobile-view {
    height: 420px !important;
  }

  .hero-text {
    margin-top: 0px !important;
  }

  .row {
    padding-right: 0 !important;
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .page-header-text {
    padding-left: 0 !important;
    padding-right: 0  !important;
    padding-top: 0 !important;
    padding-bottom: 0  !important;
  }

  .page-header {
    padding:60px;
    margin-bottom:30px;
    margin-top: 87px;
    background-color: rgba(0, 0, 0, 0.65);
    height:420px;
    overflow: hidden;
    position: relative;
  }


  .about-us {
    padding: 0 !important;
  }

  .secondary-nav-container {
    display:none;
  }

  .button_container{
    display:block;
  }

  .overlay-header {

    p {
      font-size: 20px !important;
    }
  }

  .overlay-main {
    overflow-y: auto !important;
    height: 100%;
}

.overlay-section {
  height: 100%;
}

  .hero {
    padding: 100px 20px 0px 20px !important;
    text-align: center !important;
    h2{
      text-align: center!important;
    }
  }

  .inner-box {
    padding:10px;
  }

  .bsb-product-txt{
    padding:40px 10px 40px 10px;
    text-align: center;
  }
  
  .home-product-img, .main-product-img {
    max-height:260px;

  }
  .footer-mid ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  .advisor-mobile-name {
    display: block;
  }
  .btn-margin{
    margin:0px;
  }
  .page-header{
    padding:50px 20px 0px 20px !important;
  }
  .page-header-text{
    text-align:center !important;
  }
  .section-title{
    padding: 30px 15px !important;

  }
  .filter-input{
    display:none !important;
  }


  }

  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
  /* Styles */
  .secondary-nav-container {
    display:none;
  }
  .button_container {
    display:block;
  }
  .footer-mid{
    ul{
      column-count: 1;
      }
  }


  }

  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
  /* Styles */


  }



  // Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

  .overlay-main {
    overflow-y:auto !important;
    height: 100% !important;
}

  .secondary-nav-container {
    display:none;
  }
  .button_container{
    display:block;
  }
  .footer-mid{
    ul{
      column-count: 1!important;
      }
  }

 }


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .overlay-main {
    overflow-y:auto !important;
    height: 100% !important;
}

  .button_container{
    display:block;
  }

  .secondary-nav-container {
    display:none;
  }

  .hero{
    padding:200px!important;
  }

  .overlay.open {
    width: 75%!important;
    height: 100%;
}

.overlay-section {
  height: 500px;
}

.button_container{
  display:block;
}

.dropdown:hover .dropdown-menu, .btn-group:hover .dropdown-menu {
  display: block;
}
.dropdown-menu {
  margin-top: 0;
}
.dropdown-toggle {
  margin-bottom: 2px;
}
.navbar .dropdown-toggle, .nav-tabs .dropdown-toggle {
  margin-bottom: 0;
}

.hero {
  width: 100vw !important;
}

.video {
  width: 100vw !important;
  height: 100vw !important;
}

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .secondary-nav-container {
    display:block;
  }

  .button_container{
    display:none;
  }

  .hero {
    width: 100vw !important;
  }

  .video {
    width: 100vw !important;
    height: 100vw !important;
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .button_container{
    display:none;
  }

  .hero {
    width: 100vw !important;
  }

  .video {
    width: 100vw !important;
    height: 100vw !important;
  }

}
