@charset "UTF-8";.slick-slider,html{-webkit-tap-highlight-color:transparent;box-sizing:border-box}address,dl,ol,p,ul{margin-bottom:1rem}.collapsing,.modal,.modal-open,.progress,svg:not(:root){overflow:hidden}dd,h1,h2,h3,h4,h5,h6,label{margin-bottom:.5rem}address,legend{line-height:inherit}progress,sub,sup{vertical-align:baseline}button,hr,input{overflow:visible}dl,ol,p,pre,ul{margin-top:0}.dropdown-menu,.nav,.navbar-nav{list-style:none}.btn-group-vertical,.custom-controls-stacked{-webkit-box-direction:normal;-webkit-box-orient:vertical}.custom-control-indicator,.custom-file-control,.page-item.disabled .page-link,.search-form a.disabled.search-submit,.search-form fieldset[disabled] a.search-submit,.slick-slide.dragging img,a.btn.disabled,fieldset[disabled] .search-form a.search-submit,fieldset[disabled] a.btn{pointer-events:none}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  /* Styles */

}

.navbar-nav{
  margin-right:100px;

}

// .overlay-main-header {
//   position: absolute !important;
// }

#menu-main.navbar-nav .nav-link {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 32px;
  padding-right: 32px;
}

#menu-main.navbar-nav .portalBtn.nav-link {
  padding-left: 30px;
  padding-right: 30px;
}

.dropdown-toggle:hover {
  text-decoration: none !important;
}

.dropdown-toggle::after {
  font-family: 'untitled-font-1' !important;
  border: 0 !important;
  vertical-align: -10% !important;
  display: inline-block !important;
  content: "\61" !important;
}

li.portalBtn {
  background-color:#fff !important;
  border:1px solid $bsb-darkgrey !important;
  padding-top: 0 50px 0 50px !important;
  margin-right: 25px !important;

  &:hover {
    background-color:$bsb-blue !important;
    border:1px solid $bsb-blue !important;
    cursor: pointer;

    a {
      color: #fff !important;
      text-decoration: none !important;
    }
  }
  a {
    padding-top: 10px;
    vertical-align: 9% !important;
  }
}

.nav-item{
  margin:0px 20px;
}
.nav-pills .nav-link.active{
  background-color:#f0f0f0;
  color:#ffffff;
  a{
    color:$bsb-blue;
    text-decoration: none;
    &:hover{
      color:$bsb-blue;
      text-decoration: none;

    }

  }
}
.navbar-expand-sm .navbar-nav .nav-link {
  padding-right:1.5rem;
  padding-left:1.5rem;
}

 .navbar-nav .nav-link{
  padding: 15px 20px;
  border-radius: 2px;
  font-weight: 600;
  text-align: left;
  color: $bsb-darkgrey;
  a{
    color: $bsb-darkgrey;
    text-decoration: none;
    &:hover{
      color:$bsb-blue;
      text-decoration: underline;

    }

  }

}
.fixed-top {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 2px -2px  $bsb-darkgrey;
  -moz-box-shadow: 0 1px 2px -2px  $bsb-darkgrey;
  box-shadow: 0 2px 1px -2px  $bsb-darkgrey;
}

.navbar-light .navbar-nav .nav-link.active{
  color:#ffffff;
}
.menu-bar {
  color: $bsb-blue !important;
  font-size: 24px;
}

.navbar-nav .dropdown-menu {
  min-width:12rem !important;
  font-size:14px;
  background-color:#fff;
  padding:10px;
  border:1px solid #fcfcfc;
  border-radius: 2px !important;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.75);border-radius:0;
  left:20px;}.dropdown-menu li{padding-bottom:10px;
  }

  .nav-link a {
    font-size:14px !important;
    color: #fff;
  }

  #menu-secondary-menu {
    margin-top: -10px;
    .nav-link.active {
      background-color:$bsb-blue;
      text-decoration: none !important;
    }

    li a {
      color: #fff;
      font-weight: normal;
      font-size: 13px;
    }

  }
  
  .secondary-nav-container {
    background-color:$bsb-blue;
    height:53px;

    ul li {
      font-size:16px;
      font-weight:normal;
    }
  }

  #menu-secondary-menu .dropdown.active
   {
     &:hover {
      text-decoration: underline;
      text-decoration-color: $bsb-blue;
     }
    
  }

  #menu-secondary-menu .dropdown-menu li a {
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      text-decoration-color: $bsb-blue;
    }
  }

  .dropdown-menu li a {
    color: $bsb-darkgrey !important;
  }
  
video {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: -1000;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-position: 50%;
}

.hero{
  background-color: rgba(0, 0, 0, 0.65);
   text-align: left;
  color: #ffffff;
  padding: 75px;
  margin-top: 87px;
  margin-bottom:30px;
  overflow: hidden;
  position: relative;
  height:100vh;
}

.hero-text{
margin-top:100px;
}

.hero-text h1{
  font-size: 40px;
  font-weight: 600;
  font-style: italic;
  text-transform: uppercase;
  margin-bottom:30px;
  font-family: 'Josefin Sans', sans-serif;

}
.hero-text h2{

  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  color: #ffffff;
  margin-bottom:30px;

}

//PRODUCT HEADERS
.product-header-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1000;
  height:420px;
  filter: blur(5px);
}
.product-header{
  padding:60px;
  margin-bottom:30px;
  margin-top: 87px;
  background-color: rgba(0, 0, 0, 0.65);
  height:420px;
  overflow: hidden;
  position: relative;

  .product-header-text{
    padding: 48px 0px;
    color: #ffffff;
    text-align: left;
    h1{
      font-family: 'Josefin Sans', sans-serif;
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 600;
      font-style: italic;
      margin-bottom:30px;

    }
    h2{
      font-size: 24px;
      font-weight: 500;
      line-height: 1.5;
      color: #ffffff;
      margin-bottom:30px;

    }
  }

  .product-header-text h2{
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    color: #ffffff;
    margin-bottom:30px;
  }

}

//PAGE HEADERS
.page-header-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1000;
  height:300px;
  filter: blur(5px);
  background: url("../images/page-banner.jpg");
}
.page-header{

    // background-size:cover;
    // background-repeat: no-repeat;
    // background-color: rgba(0, 0, 0, 0.65);
    // width: 100vw;
    // height: 50vh;
    // background-position: center center;
    // display: flex;
    // justify-content: center;
    // align-items: center;

  padding:60px;
  margin-bottom:30px;
  margin-top: 87px;
  background-color: rgba(0, 0, 0, 0.65);
  height:300px;
  overflow: hidden;
  position: relative;

  .page-header-text{
    padding: 50px 0px;
    color: #ffffff;
    text-align: left;
    h1{
      font-family: 'Josefin Sans', sans-serif;
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 600;
      font-style: italic;
      margin-bottom:30px;

    }
    h2{
      font-size: 24px;
      font-weight: 500;
      line-height: 1.5;
      color: #ffffff;
      margin-bottom:30px;

    }
  }

  .page-header-text h2{
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    color: #ffffff;
    margin-bottom:30px;
  }

}

.scroll-down {
  opacity: 1;
  -webkit-transition: all .5s ease-in 3s;
  transition: all .5s ease-in 3s;
}
.scroll-down {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb 2s infinite;
  animation: sdb 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes sdb {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}

#section03 a {
  padding-top: 60px;
}
#section03 a span {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 46px;
  height: 46px;
  margin-left: -23px;
  border: 1px solid #fff;
  border-radius: 100%;
  box-sizing: border-box;
}
#section03 a span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  width: 16px;
  height: 16px;
  margin: -12px 0 0 -8px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}
#section03 a span::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  content: '';
  width: 44px;
  height: 44px;
  box-shadow: 0 0 0 0 rgba(255,255,255,.1);
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: sdb03 3s infinite;
  animation: sdb03 3s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 60px rgba(255,255,255,.1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 60px rgba(255,255,255,.1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


// OVERLAY



.overlay-header{
  height:100px;
   background-color:#4d738a;
   color:#ffffff;
   font-size: 24px;
   font-weight: 500;
  text-align: left;
  padding:20px;
  border-bottom:2px solid #ffffff;


 }
  .overlay-section{

  background-color:#4d738a;
  // padding:0px;
  color:#ffffff;
  p{
    font-size: 20px;
  font-weight: 500;

  }
   h2{

    font-size:18px;
    font-weight: 800;
   }
 }

.button_container {
  position: fixed;
  top: 0px;
  right: 0;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 100;
  transition: opacity .25s ease;
  margin: 30px 10px 0px 10px;
}
.button_container:hover {
  opacity: .7;
}
.button_container.active .top {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #FFF;
}
.button_container.active .middle {
  opacity: 0;
  background: #FFF;
}
.button_container.active .bottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #FFF;
}
.button_container span {
  background: #4d738a;
  border: none;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 11px;
}
.button_container span:nth-of-type(3) {
  top: 22px;
}

.overlay {
  position: fixed;
  background: #4d738a;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, width .35s;
  overflow: hidden;}
.overlay h1{
  font-size: 22px;
  font-weight: 800;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  margin:20px 0px 0px 0px;
  a{
    color: #ffffff;
    text-decoration: none;
    &:hover{
      text-decoration: underline

    }
  }
}
.overlay ul{
  -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
    list-style: none;
     margin:20px 0px 20px 0px;
     padding: 0;
    list-style-type: none;

}
.overlay li{
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  text-align: left;
  text-transform: capitalize;
  margin:0px 0px 10px 0px;
  a{
    color: #ffffff;
    text-decoration: none;

    &:hover{
      text-decoration: underline

    }
  }
}
.overlay.open {
  opacity: 1;
  visibility: visible;
  width: 100%;
}


@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
