.read-more{
  color: rgba($bsb-blue, 0.9);
  &:hover{
    color: rgba($bsb-blue, 0.5);
    cursor: pointer;
  }
}


.filter-text, .filter-label { font-size:0.75em }
.filter-label { margin-right:1em }
.filter-wrap { position:relative; overflow:hidden;  }
.filter-item { float:left;  text-align:center }
.filter-item b { background:#fff; background:rgba(255,255,255,0.65);}

.filter-mask { position:absolute; top:0; left:0; bottom:0; right:0; -webkit-animation:filterMask 1s ease-in-out both; animation:filterMask 1s ease-in-out both }
@-webkit-keyframes filterMask {
	0% { background:transparent }
	50% { background:#fff }
	100% { background:transparent }
	}
@keyframes filterMask {
	0% { background:transparent }
	50% { background:#fff }
	100% { background:transparent }
  }
  

  @media (min-width : 320px) and (max-width : 480px) {
    /* Styles */
  
    .about-us {
      padding: 0 !important;
    }
  }

  //  About Us Page //
.about-us{
  padding:40px 150px;

  h1{
    font-family: Barlow;
  font-size: 34px;
  font-weight: 800;

  }
  h2{
    font-family: Barlow;
    font-size: 24px;
    font-weight: bold;
    margin:40px 0px 20px 0px;

  }
  h5{
    font-family: Barlow;
    font-size: 24px;
    font-weight: normal;
    margin:40px 0px 20px 0px;

  }
  p{
    font-family: Barlow;
  font-size: 18px;
  font-weight: 500;

  }
}
.about-testimonial{
  min-height: 400px;
}


.slick-dots {
    // text-align: right;
    // padding-top: 15px;
    // bottom: -80px;

  li {
    display: inline-block;
    vertical-align: top;
    margin: 0 8px;
    button {
      width: 16px;
      height: 16px;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      border: 2px solid #ccc;
      box-shadow: 0 0 0 0 transparent;
      vertical-align: middle;
      color: #fff;
      background-color: #fff;
      transition:all .3s ease;
      opacity: .4;
      &:before {
        content: '•';
        font-size: 2px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        text-align: center;
        opacity: .25;
        color: #fff!important;
    }
      &:focus {
        outline: none;
      }
      &:hover {
        opacity: 1;
      }
    }
    &.slick-active {
      button {
        border-color: #4d738a;
        // box-shadow: 0 0 0 2px #fff;
        opacity: 1;
      }
    }
  }
}

.blog-page{
  font-family: 'Barlow', sans-serif;

  h1{
     font-size: 1.5em;
      font-weight: bold;
      color: $bsb-blue;
      margin: 20px 0px;
    }
    h2{
      font-size: 1.2em;
      a{
        text-decoration: none;
        color: $bsb-blue;
      }

    }
    p{
      font-size: 0.75em;
      a{
        display:none;
     }

    }

}
.blog-readMore{
  a{
    display:block;
 }

}

.blog-page__image{
  margin:10px 0px;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
