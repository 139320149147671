// Grid settings
@import url('https://fonts.googleapis.com/css?family=Barlow:400,600,800|Josefin+Sans:400,700');


$main-sm-columns:       12;
$sidebar-sm-columns:    4;


$bsb-grey: #f7f7f7;
$bsb-darkgrey: #777777;
$bsb-blue:#4d738a;
$bsb-white: #ffffff;


