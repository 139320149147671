input[type=checkbox] {
 -webkit-appearance:button;
 border: solid 2px #666666;
 background-color:#f7f7f7;
 border-radius: 2px;
 white-space: nowrap;
 overflow:hidden;
 width:18px;
 height:18px;
 transition: background-color 250ms cubic-bezier(.4,.0,.23,1);
 padding:8px;
}

input[type=checkbox]:checked {
  border: solid 2px #4d738a;
background-color:#4d738a;
 transition: background-color 250ms cubic-bezier(.4,.0,.23,1);
 background: url("../images/form-checkmark.png");
}


