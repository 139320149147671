
.blog-sidebar{
  font-family: 'Barlow', sans-serif;

  h1{
     font-size: 1.5em;
      font-weight: bold;
      color: $bsb-blue;
      margin: 20px 0px;
    }
    h2{
      font-size: 1.2em;
      a{
        text-decoration: none;
        color: $bsb-blue;
      }

    }
    p{
      font-size: 0.75em;

    }
}
.blog-sidebar__image{
  margin:10px 0px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

}
.blog-sidebar__title{

}
.blog-sidebar__text{

}
.blog-search{

}
.search-field{
  margin-right:20px;
}
